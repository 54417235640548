<template>
  <div class="stepsdemo-content">
    <Card class="py-10">
      <template v-slot:title>
        <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
          Editar Persona
        </h2>
      </template>
      <template v-slot:content>
        <div class="p-fluid p-2 mx-auto w-full lg:w-3/4 xl:w-1/2 flex justify-center items-center gap-4">
          <div class="p-fluid formgrid grid w-full">
            <div class="field my-3 col-12">
              <div class="p-float-label">
                <InputText class="uppercase" id="fullName" v-model="model.fullname"/>
                <label for="fullName labelSpace">
                  Nombre completo
                </label>
              </div>
              <MessageError :text="errors.fullname"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <Dropdown id="name"
                          v-model="model.docTipoId"
                          :options="tiposDocumentos"
                          option-label="nombre"
                          option-value="code"
                          :filter="true"
                          style="min-width: 241px"
                />
                <label for="numeroIdentificacion">
                  Tipo identificación
                </label>
              </div>
              <MessageError :text="errors.numeroIdentificacion"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <InputText id="numeroIdentificacion" v-model="model.numeroIdentificacion" :disabled="disable"/>
                <label for="numeroIdentificacion">
                  N° identificación
                </label>
              </div>
              <MessageError :text="errors.numeroIdentificacion"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <InputText id="telefono" v-model="model.telefono"/>
                <label for="telefono">
                  Numero telefono
                </label>
              </div>
              <MessageError :text="errors.telefono"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <InputText class="uppercase" id="correo" v-model="model.correo"/>
                <label for="correo">
                  Correo electronico
                </label>
              </div>
              <MessageError :text="errors.correo"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <Dropdown v-model="model.departamentoId"
                          class="w-full"
                          :options="departamentos"
                          option-label="nomDpto"
                          option-value="codDpto"
                          @change="getMpio"
                          :filter="true"
                />
                <label for="departamentoId">
                  Departamento
                </label>
              </div>
              <MessageError :text="errors.departamentoId"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <dropdown
                  class="w-full"
                  :options="municipios"
                  option-label="nomMpio"
                  option-value="codMpio"
                  v-model="model.municipioId"
                  :filter="true"
                />
                <label for="departamentoId">
                  Municipio
                </label>
              </div>
              <MessageError :text="errors.municipioId"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <InputText class="uppercase" id="fullName" v-model="model.direccion"/>
                <label for="fullName">
                  Dirección de residencia
                </label>
              </div>
              <MessageError :text="errors.direccion"/>
            </div>
            <div class="field my-3">
              <div class="p-float-label">
                <InputText class="uppercase" id="fullName" v-model="model.representanteLegal"/>
                <label for="fullName">
                  Nombre del representante (opcional)
                </label>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
          <div class="flex w-full justify-center md:justify-center px-2">
            <!-- <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left" /> -->
            <Button label="Continuar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import storeLocalizacion from '@/apps/pharmasan/juridica/procesosJuridicos/store/localizacion.store'
import DocTipoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/docTipo.service'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
import derechosPeticionStore from '../../../../../store/derechosPeticion.store'

export default {
  name: 'DatosPersona',
  setup () {
    /** instancias **/
    const _DocTipoService = new DocTipoService()
    /** data **/
    const tiposDocumentos = ref([])
    const disable = ref(false)
    const listLocalizaciones = computed(() => {
      return storeLocalizacion.getters._departamentos
    })
    const router = useRouter()
    /** state **/
    const validationSchema = object({
      fullname: string().nullable().required().label('Nombre completo'),
      numeroIdentificacion: string().nullable().required().label('N° identificación'),
      telefono: string().nullable().required().label('Numero de telefono'),
      correo: string().nullable().required().email().label('Correo electronico'),
      departamentoId: yup.string().required('El campo es requerido').label(''),
      municipioId: yup.string().required('El campo es requerido').nullable().label(''),
      representanteLegal: string().nullable().label('Representante legal'),
      direccion: string().nullable().required().label('Dirección')
    })
    const { errors, handleSubmit, values: model } = useForm({
      validationSchema
    })
    useField('fullname')
    useField('numeroIdentificacion')
    useField('telefono')
    useField('correo')
    useField('departamentoId')
    useField('municipioId')
    useField('representanteLegal')
    useField('direccion')
    /** methods **/
    const nextPage = handleSubmit((values) => {
      derechosPeticionStore.commit('updatePersona', values)
      router.push('datos-derecho-peticion', { formData: { ...values }, pageIndex: 1 })
    })
    // const prevPage = () => {
    //   router.push('buscar-persona', { pageIndex: 1 })
    // }
    const getMpio = ({ value }, municipioId = null) => {
      if (!value) {
        storeLocalizacion.commit('setMunicipios', [])
      } else {
        storeLocalizacion.dispatch('getMunicipios', value).then(() => {
          model.municipioId = municipioId
        })
      }
    }
    /** computed **/
    const derecho = computed(() => derechosPeticionStore.getters._derecho)
    const departamentos = computed(() => storeLocalizacion.getters._departamentos)
    const municipios = computed(() => storeLocalizacion.getters._municipios)

    /** watch **/
    watch(() => derecho.value, (value) => {
      if (value) {
        const { accionante: acc } = value
        model.telefono = acc.telefono
        model.fullname = acc.fullname
        model.correo = acc.correo
        model.docTipoId = acc.docTipoId
        model.departamentoId = acc.departamentoId
        model.numeroIdentificacion = acc.numeroIdentificacion
        model.representanteLegal = acc.representanteLegal
        model.direccion = acc.direccion
        getMpio({ value: acc.departamentoId }, acc.municipioId)
      }
    })
    /** hooks **/
    onMounted(async () => {
      disable.value = true
      _DocTipoService.getAll().then(({ data }) => {
        tiposDocumentos.value = data
        // const { accionante: acc } = derecho.value
        // console.log(derecho.value)
        // model.telefono = acc.telefono
        // model.fullname = acc.fullname
        // model.correo = acc.correo
        // model.docTipoId = acc.docTipoId
        // model.departamentoId = acc.departamentoId
        // model.numeroIdentificacion = acc.numeroIdentificacion
        // model.representanteLegal = acc.representanteLegal
        // model.direccion = acc.direccion
        // getMpio({ value: acc.departamentoId }, acc.municipioId)
        // if (acc.numeroIdentificacion) {
        // }
      })
      await storeLocalizacion.dispatch('getMunicipios', 81)
    })
    return {
      nextPage,
      errors,
      model,
      tiposDocumentos,
      listLocalizaciones,
      departamentos,
      municipios,
      getMpio,
      // prevPage,
      derecho,
      disable
    }
  }
}
</script>
<style>
::v-deep(.p-float-label label) {
  margin-top: -1.2rem !important;
}
</style>
